<template>
  <div>
    <headers />
    <div class="mb-30">
      <div class="relative" style="opacity: 1;">
        <div data-testid="hero-video-bg" class="top-0 left-0 z-[-1] h-full w-full relative md:absolute">
          <div class="relative w-full h-full overflow-hidden max-h-[80vh] h-[520px] md:h-[650px] md:max-h-full w-full">
            <div autoplay="" style="width: 100%; height: 100%;">              
              <!-- <video src="https://stream.mux.com/ykbIkKgzztKGsD601idRDlEYXT00OfXZBk/high.mp4" preload="auto" autoplay="" loop="" crossorigin="anonymous" class="object-cover object-center md:h-[100%]" playsinline="" webkit-playsinline="" x5-playsinline="" style="width: 100%; height: 100%;"></video> -->
                <img style="width: 100%; height: 100%;" :src="require('@/assets/images/InvestmentManagemen/b1.png')" alt="" />
              </div>
          </div>
        </div>
        <div class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] relative
          md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px]
          xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:h-[650px]">
          <div
            class="col-end-flex flex flex-col col-start-2 col-span-7 md:col-start-2 md:col-span-15 lg:col-start-2 h-full justify-center z-1">
            <div class="relative my-8 md:my-5 flex flex-col items-start text-left content-start justify-start">
              <h1 class="text-2xl md:text-4xl text-iblack-700 md:text-iblack-300 dark:text-iblack-300 max-w-[700px]">
                Zen Capital is an investment firm <br class="hidden md:inline"> focused on the long-term structured <br class="hidden md:inline"> value investments, </h1>
              <p class="text-lg text-iblack-600 md:text-iblack-400 dark:text-iblack-400 mt-2.5 md:mt-2.5 max-w-[500px]">
                which currently manages approximately $2.5 billion of assets.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x:hidden">
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class=" block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] ">
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="flex items-center main-grid--in-grid justify-center">
          <div class="
    block
    md:grid md:grid-cols-6 md:gap-4
    w-full
  ">
            <div class="col-span-6">
              <div class="
    prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400
    prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal
  md:prose-h1:text-4xl prose-h1:text-2xl
  md:prose-h2:text-3xl prose-h2:text-xl
  md:prose-h3:text-2xl prose-h3:text-xl
  md:prose-h4:text-xl prose-h4:text-lg
  md:prose-h5:text-lg prose-h5:text-lg
  prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal
  prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal
  prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal
  prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal
  prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal
  prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100
  prose-a:dark:text-iblack-300
  prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100
  prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100
  prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100
  prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100
  prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300
  prose-li:marker:text-iblack-600
  prose-a:dark:text-iblack-300
  prose-p:text-iblack-600 dark:prose-p:text-iblack-400
  prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 md:text-center" style="opacity: 1; transform: none;">
              <div class="text-center">
                <h1 class="my-[30px] font-medium">Diverse Sources, Differentiated Capital, and Deep Trust.</h1>
                <p class="m-0"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-grid mb-30">
      <div class="main-grid--full-grid">
        <div class="flex flex-col relative">
          <div
            class=" block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] ">
            <div class="pb-[30px] md:pb-0 col-span-11 col-start-2  relative">
              <div><a draggable="false" target="_self"
                  class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                  href="/investment-management/investment-portfolios">
                  <section class="">
                    <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      style="opacity: 1; transform: none;">
                      <img class="h-full w-full object-cover object-center"
                          :src="require('@/assets/images/InvestmentManagemen/h1.png')"
                          alt="" draggable="false">
                    </div>
                    <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                      <div class=" col-span-5 ">
                        <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          style="opacity: 1; transform: none;">Investment Portfolios</h1>
                        <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none;">Our aim is to deliver
                          exceptional investment portfolios by harnessing the differentiated strategic strength of the
                          ZEN CAPITAL community.</p>
                      </div>
                    </div>
                  </section>
                </a></div>
            </div>
            <div class="col-span-11 col-start-14  relative">
              <div><a draggable="false" target="_self"
                  class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                  href="/investment-management/family-advisory">
                  <section class="">
                    <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      style="opacity: 1; transform: none;">
                      <img class="h-full w-full object-cover object-center"
                          :src="require('@/assets/images/InvestmentManagemen/h2.png')"
                          alt="" draggable="false">
                    </div>
                    <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                      <div class=" col-span-5 ">
                        <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          style="opacity: 1; transform: none;">Family Advisory</h1>
                        <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none;">We seek to create bespoke
                          solutions to optimize the financial lives of our families.</p>
                      </div>
                    </div>
                  </section>
                </a></div>
            </div>
            <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-grid mb-30">
      <div class="main-grid--full-grid">
        <div class="flex flex-col relative">
          <div
            class=" block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] ">
            <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footers />
</div></template>
<script>
import headers from '@/components/head';
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
